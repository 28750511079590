import React from "react";
import { withRouter } from "react-router-dom";

class Choose extends React.Component {
  handleClick(e) {
    let { offsetWidth, offsetHeight } = e.target;
    let { offsetX, offsetY } = e.nativeEvent;

    let halfwayX = offsetWidth / 2;
    let halfwayY = offsetHeight / 2;

    let isInTopHalf = offsetY < halfwayY;
    let isOnLeft = offsetX < halfwayX;
    let isInBottomHalf = offsetY >= halfwayY;
    let isOnRight = offsetX >= halfwayX;

    if (isOnLeft && isInTopHalf) {
      this.props.history.push("/60seconds");
    }

    if (isOnLeft && isInBottomHalf) {
      this.props.history.push("/60days");
    }

    if (isOnRight && isInTopHalf) {
      this.props.history.push("/60minutes");
    }

    if (isOnRight && isInBottomHalf) {
      this.props.history.push("/info");
    }
  }
  render() {
    return (
      <div className="route-choose">
        <div className="choose-text">
          {/* <strong>Select your challenge below&nbsp;</strong> */}
          <strong>
            Breaktocreate is part of ARTIVISM, the first-ever human rights
            pavilion at the Venice Biennale. The exhibition explores the
            fundamental role the arts play in promoting human rights, and
            breaktocreate illustrates how we can all be activists through our
            talents, imagination and, importantly, our critical thinking.
          </strong>
          <p>
            But first, we must break things: our assumptions, our silence and
            our inaction. Whether you can commit 60 seconds, 60 minutes and/or
            60 days, select your challenge below.
          </p>
        </div>

        <img
          src="/images/choose.png"
          alt="choose"
          onClick={this.handleClick.bind(this)}
        />
      </div>
    );
  }
}

export default withRouter(Choose);
