export default () => {
  return {
    head: [
      {src: '1-1.png'},
      {src: '2-1.png'},
      {src: '3-1.png'},
      {src: '4-1.png'},
      {src: '5-1.png'},
      {src: '6-1.png'}
    ],
    torso: [
      {src: '1-2.png'},
      {src: '2-2.png'},
      {src: '3-2.png'},
      {src: '4-2.png'},
      {src: '5-2.png'},
      {src: '6-2.png'}
    ],
    legs: [
      {src: '1-3.png'},
      {src: '2-3.png'},
      {src: '3-3.png'},
      {src: '4-3.png'},
      {src: '5-3.png'},
      {src: '6-3.png'}
    ],
    accessories: [
      {
        position: 'head',
        menuSrc: 'accessories/1-glasses.png',
        srcs: [
          'accessories/1-glasses/1.png',
          'accessories/1-glasses/2.png',
          'accessories/1-glasses/3.png',
          'accessories/1-glasses/4.png',
          'accessories/1-glasses/5.png',
          'accessories/1-glasses/6.png'
        ]
      },
      {
        position: 'head',
        menuSrc: 'accessories/2-hat.png',
        srcs: [
          'accessories/2-hat/1.png',
          'accessories/2-hat/2.png',
          'accessories/2-hat/3.png',
          'accessories/2-hat/4.png',
          'accessories/2-hat/5.png',
          'accessories/2-hat/6.png'
        ]
      },
      {
        position: 'head',
        menuSrc: 'accessories/3-patch.png',
        srcs: [
          'accessories/3-patch/1.png',
          'accessories/3-patch/2.png',
          'accessories/3-patch/3.png',
          'accessories/3-patch/4.png',
          'accessories/3-patch/5.png',
          'accessories/3-patch/6.png'
        ]
      },
      {
        position: 'torso',
        menuSrc: 'accessories/4-cape.png',
        srcs: [
          'accessories/4-cape/1.png',
          'accessories/4-cape/2.png',
          'accessories/4-cape/3.png',
          'accessories/4-cape/4.png',
          'accessories/4-cape/5.png',
          'accessories/4-cape/6.png'
        ]
      },
      {
        position: 'torso',
        menuSrc: 'accessories/5-fono.png',
        srcs: [
          'accessories/5-fono/1.png',
          'accessories/5-fono/2.png',
          'accessories/5-fono/3.png',
          'accessories/5-fono/4.png',
          'accessories/5-fono/5.png',
          'accessories/5-fono/6.png'
        ]
      },
      {
        position: 'torso',
        menuSrc: 'accessories/6-light.png',
        srcs: [
          'accessories/6-light/1.png',
          'accessories/6-light/2.png',
          'accessories/6-light/3.png',
          'accessories/6-light/4.png',
          'accessories/6-light/5.png',
          'accessories/6-light/6.png'
        ]
      },
      {
        position: 'torso',
        menuSrc: 'accessories/7-net.png',
        srcs: [
          'accessories/7-net/1.png',
          'accessories/7-net/2.png',
          'accessories/7-net/3.png',
          'accessories/7-net/4.png',
          'accessories/7-net/5.png',
          'accessories/7-net/6.png'
        ]
      },
      {
        position: 'torso',
        menuSrc: 'accessories/8-spoon.png',
        srcs: [
          'accessories/8-spoon/1.png',
          'accessories/8-spoon/2.png',
          'accessories/8-spoon/3.png',
          'accessories/8-spoon/4.png',
          'accessories/8-spoon/5.png',
          'accessories/8-spoon/6.png'
        ]
      },
      {
        position: 'torso',
        menuSrc: 'accessories/9-typewriter.png',
        srcs: [
          'accessories/9-typewriter/1.png',
          'accessories/9-typewriter/2.png',
          'accessories/9-typewriter/3.png',
          'accessories/9-typewriter/4.png',
          'accessories/9-typewriter/5.png',
          'accessories/9-typewriter/6.png'
        ]
      },
      {
        position: 'legs',
        menuSrc: 'accessories/10-skate.png',
        srcs: [
          'accessories/10-skate/1.png',
          'accessories/10-skate/2.png',
          'accessories/10-skate/3.png',
          'accessories/10-skate/4.png',
          'accessories/10-skate/5.png',
          'accessories/10-skate/6.png'
        ]
      },
      {
        position: 'legs',
        menuSrc: 'accessories/11-trumpet.png',
        srcs: [
          'accessories/11-trumpet/1.png',
          'accessories/11-trumpet/2.png',
          'accessories/11-trumpet/3.png',
          'accessories/11-trumpet/4.png',
          'accessories/11-trumpet/5.png',
          'accessories/11-trumpet/6.png'
        ]
      },
      {
        position: 'legs',
        menuSrc: 'accessories/12-wings.png',
        srcs: [
          'accessories/12-wings/1.png',
          'accessories/12-wings/2.png',
          'accessories/12-wings/3.png',
          'accessories/12-wings/4.png',
          'accessories/12-wings/5.png',
          'accessories/12-wings/6.png'
        ]
      }
    ]
  };
};
