import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

class Home extends React.Component {
  goToChoices() {
    this.props.history.push("/choose");
  }
  render() {
    return (
      <div className="route-home" onClick={this.goToChoices.bind(this)}>
        <div className="welcome-image">
          <img src="/images/welcome.png" alt="welcome to breake to create" />
        </div>
        <div className="cello-image">
          <img src="/images/cello.png" alt="cello" />
        </div>
        <p>
          “Every act of creation is first an act of destruction” - Pablo Picasso
        </p>
        <Button
          variant="contained"
          color="primary"
          className="black-button bigger-button"
        >
          continue
        </Button>
      </div>
    );
  }
}

export default withRouter(Home);
