import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Break from "./Break";
import BuildHero from "./BuildHero";
import MyStory from "./MyStory";
import MyHero from "./MyHero";
import Choose from "./Choose";
import Finish from "./Finish";
import SixtyDays from "./SixtyDays";
import Info from "./Info";
import SixtySeconds from "./SixtySeconds";
import SixtyMinutes from "./SixtyMinutes";

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/break" component={Break} />
      <Route path="/build-hero" component={BuildHero} />
      <Route path="/my-story" component={MyStory} />
      <Route path="/my-hero" component={MyHero} />
      <Route path="/choose" component={Choose} />
      <Route path="/finish" component={Finish} />
      <Route path="/60days" component={SixtyDays} />
      <Route path="/60seconds" component={SixtySeconds} />
      <Route path="/60minutes" component={SixtyMinutes} />
      <Route path="/info" component={Info} />
    </Switch>
  </main>
);

export default Main;
