import React from "react";
import ReactDOM from "react-dom";
import GetImages from "../data/hero-images";
import { generateHeroUrl } from "../utils/image-urls";

class RenderHero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageCache: {},
      possibleImages: this.setupPossibleImages()
    };
  }
  componentWillMount() {
    if (this.props.selectedIndexes) {
      this.setState({
        selectedIndexes: this.props.selectedIndexes,
        selectedAccessories: this.props.selectedAccessories
      });
    }
    this.preloadImages();
  }

  setupPossibleImages() {
    return GetImages();
  }

  generateUrl(filename) {
    return generateHeroUrl(filename);
  }

  preloadImages() {
    let possibleImages = this.state.possibleImages;
    for (let bodyPart in possibleImages) {
      let array = possibleImages[bodyPart];
      array.forEach(image => {
        let url = this.generateUrl(image.src);
        this.loadImage(url);
      });
    }
  }

  createImage(url) {
    var img = new Image();
    img.src = url;
  }
  loadImage(src) {
    return new Promise(resolve => {
      let image = new Image();
      image.src = src;
      image.onload = () => {
        this.setState(this.cacheImage(src, image));
        resolve(image);
      };
    });
  }
  cacheImage(src, image) {
    return (state, props) => {
      let newCache = {
        ...state.imageCache
      };
      newCache[src] = image;
      state.imageCache = newCache;
      return null;
    };
  }
  getImage(src) {
    return this.state.imageCache[src];
  }
  async drawHero() {
    let canvas = ReactDOM.findDOMNode(this);
    let parent = canvas.parentNode;
    let context = canvas.getContext("2d");
    context.canvas.width = parent.offsetWidth;
    context.canvas.height = parent.offsetHeight;

    let headImage = this.getImage(this.props.headImage);
    if (!headImage) {
      headImage = await this.loadImage(this.props.headImage);
    }

    let torsoImage = this.getImage(this.props.torsoImage);
    if (!torsoImage) {
      torsoImage = await this.loadImage(this.props.torsoImage);
    }

    let legsImage = this.getImage(this.props.legsImage);
    if (!legsImage) {
      legsImage = await this.loadImage(this.props.legsImage);
    }

    context.drawImage(headImage, 0, 0, parent.offsetWidth, parent.offsetHeight);
    context.drawImage(
      torsoImage,
      0,
      0,
      parent.offsetWidth,
      parent.offsetHeight
    );
    context.drawImage(legsImage, 0, 0, parent.offsetWidth, parent.offsetHeight);

    let accessoryImages = this.props.accessoryImages;
    for (let index = 0; index < accessoryImages.length; index++) {
      let img = this.getImage(accessoryImages[index]);
      if (!img) {
        img = await this.loadImage(accessoryImages[index]);
      }
      context.drawImage(img, 0, 0, parent.offsetWidth, parent.offsetHeight);
    }
  }
  componentDidMount() {
    this.drawHero();
  }
  componentDidUpdate() {
    let canvas = ReactDOM.findDOMNode(this);
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    this.drawHero();
  }
  render() {
    return <canvas id={this.props.canvasId} />;
  }
}
export default RenderHero;
