import React from "react";
import { withRouter } from "react-router-dom";
import RenderHero from "./RenderHero";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { generateHeroUrl, getAccessoryUrls } from "../utils/image-urls";
import { getHeroFields } from "../data/hero-fields";

class MyStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canvasId: "my-hero",
      myStory: "My Story"
    };
  }
  handleStoryChange(e) {
    this.props.updateStory(e.target.value);
  }
  handleNameChange(e) {
    this.props.updateName(e.target.value);
  }
  handleChange(e) {
    const id = e.target.getAttribute("id");
    const value = e.target.value;
    this.props.updateHeroDetails({ id, value });
  }
  done() {
    this.props.goToMyHero();
  }
  back() {
    this.props.returnToCreateHero();
  }

  getDefaultValue(id) {
    const details = this.props.heroDetails;
    if (details[id]) {
      return details[id];
    }
  }

  generateInput(field) {
    const defaultValue = this.getDefaultValue(field.id);
    return (
      <TextField
        id={field.id}
        defaultValue={defaultValue}
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={this.handleChange.bind(this)}
        className={`${field.id}-input`}
        label={field.label}
        key={field.id}
      />
    );
  }

  generateArea(field) {
    const defaultValue = this.getDefaultValue(field.id);
    return (
      <TextField
        id={field.id}
        defaultValue={defaultValue}
        multiline
        rows="2"
        rowsMax="10"
        fullWidth
        margin="normal"
        variant="outlined"
        className={`${field.id}-input`}
        label={field.label}
        onChange={this.handleChange.bind(this)}
        key={field.id}
      />
    );
  }

  generateForm() {
    let formFields = getHeroFields();
    return formFields.map(field => {
      switch (field.type) {
        case "input":
          return this.generateInput(field);
        case "area":
          return this.generateArea(field);
        default:
          return this.generateInput(field);
      }
    });
  }

  render() {
    let headImage = generateHeroUrl(this.props.hero.head);
    let torsoImage = generateHeroUrl(this.props.hero.torso);
    let legsImage = generateHeroUrl(this.props.hero.legs);
    let accessoryImages = getAccessoryUrls(this.props.hero.accessories);
    const formMarkup = this.generateForm();

    return (
      <div className="route-my-story">
        <h1>Tell us about your superhero...</h1>
        <Grid container spacing={16}>
          <Grid className="left-side-grid" item xs={12} md={6}>
            <div className="layout-hero">
              <div className="hero-frame">
                <div className="image">
                  <RenderHero
                    headImage={headImage}
                    torsoImage={torsoImage}
                    legsImage={legsImage}
                    accessoryImages={accessoryImages}
                    canvasId={this.state.canvasId}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="right-column">
            <div className="right-side">
              {/* <h1>Tell us about your superhero story...</h1> */}

              {formMarkup}
            </div>
            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                className="black-button bigger-button"
                onClick={this.back.bind(this)}
              >
                back
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="black-button bigger-button"
                onClick={this.done.bind(this)}
              >
                done
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(MyStory);
