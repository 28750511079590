import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import "./App.scss";
import Firebase from "firebase/app";
import "firebase/firestore";

function setupFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyAEBelG_8w91LuCaJZOgerbW7qkz4G7A80",
    authDomain: "crin-a8cca.firebaseapp.com",
    databaseURL: "https://crin-a8cca.firebaseio.com",
    projectId: "crin-a8cca",
    storageBucket: "crin-a8cca.appspot.com",
    messagingSenderId: "335016493920",
    appId: "1:335016493920:web:d63c8aec8b3cbfe9"
  };

  Firebase.initializeApp(firebaseConfig);
}

setupFirebase();

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
