import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

class SixtySeconds extends React.Component {
  componentWillMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  goToBreak() {
    this.props.history.push("/break");
  }
  render() {
    return (
      <div className="route-sixty-seconds">
        <Card className="card-sixty-seconds">
          <CardContent>
            <h1>
              60 seconds - Break your assumptions about children and young
              people and their place in society.
            </h1>
            <p>
              Just because something happens every day, it doesn’t mean it’s not
              bad and that it cannot change. We must always question the world
              we live in and the norms we live by. With critical thinking as the
              driving force, this is simply about asking questions and not
              feeling you have to accept things as they are. In a world rich
              with information, we have the resources to achieve this.
            </p>
          </CardContent>
          <CardActions className="actions-sixty-seconds">
            <Button
              className="black-button bigger-button"
              variant="contained"
              color="primary"
              onClick={this.goToBreak.bind(this)}
            >
              next
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default withRouter(SixtySeconds);
