import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

class SixtyDays extends React.Component {
  componentWillMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  goToFinish() {
    this.props.history.push("/finish");
  }
  render() {
    return (
      <div className="route-sixty-days">
        <Card className="card-sixty-days">
          <CardContent>
            <h1>60 days - Build a better world by using your talents.</h1>
            <p>
              Protecting the human rights of children and adults alike isn’t
              just the work of organisations like ours; it’s a collective
              responsibility everyone shares, because we all have human rights.
              This doesn’t mean we have to be solitary activists. After all,
              history shows us it’s harder to ignore a big group than a single
              voice, and history is full of examples of people who came together
              because they had a shared idea that then changed the world.
              Instead, it’s about combining our strengths and individual talents
              and acting as a collective.
            </p>
            <p>
              Over the next 6 months we’ll be gathering contributions from
              people who are passionate about social justice and want to use
              their talents to protecting it in some way. We’ve included some
              suggestions below to doing this, which can be carried out over 60
              days. You can do one or more, or feel free to do something
              different. Once collected, the contributions will form part of a
              collection of everyday activism, rebellion and disobedience in
              favour of human rights.
            </p>

            <h2>Draw</h2>
            <p>
              Draw your own superhero, and tell us their weekly story over 60
              days. Give your character different challenges to overcome on an
              issue of your choice, for example, environmental protection,
              government corruption, violence against children, discrimination,
              freedom of movement. Send your contribution to{" "}
              <a href="mailto:contact@crin.org">contact@crin.org</a>
            </p>

            <h2>Join</h2>
            <p>
              Join the student climate strike in your local area. And create a
              protest banner against climate change and government inaction. Let
              us know what you did: Twitter{" "}
              <a href="https://twitter.com/crinwire">@CRINwire</a> or Instagram{" "}
              <a href="https://www.instagram.com/crin_stagram">@CRIN_stagram</a>
            </p>

            <h2>Rebel</h2>
            <p>
              Perform one small act of rebellion each day and document it. You
              can challenge an unjust social norm, break a rule that most people
              follow blindly, stand up for a principle that’s taken for granted,
              boycott a product you know is produced with child labour, or speak
              out against an unfair action or comment. Remember: think
              critically. And let us know what you did: Twitter{" "}
              <a href="https://twitter.com/crinwire">@CRINwire</a> or Instagram{" "}
              <a href="https://www.instagram.com/crin_stagram">@CRIN_stagram</a>
            </p>

            <h2>Organise</h2>
            <p>
              Organise an event in your local area to address an injustice
              against children. For example, inadequate school funding, how
              local newspapers portray under-18s, lack of safe public play areas
              for children. Design and write the materials for the event too:
              the invitation, the poster, the information leaflet, list of
              advice organisations. Let us know what you did and how it went:
              Twitter <a href="https://twitter.com/crinwire">@CRINwire</a> or
              Instagram{" "}
              <a href="https://www.instagram.com/crin_stagram">@CRIN_stagram</a>
            </p>

            <h2>Advise</h2>
            <p>
              Tell us how to do things better and more effectively. You may know
              more than us on how to fundraise ethically, communicate to new
              audiences, build unlikely partnerships, develop a children’s
              rights curriculum, run a publishing company, or even use other art
              forms for social change. Please get in touch with us at{" "}
              <a href="mailto:contact@crin.org">contact@crin.org</a>
            </p>

            <h2>Contact</h2>
            <p>
              Let us know if you have other talents we haven’t thought of and
              would like to work together with us. Are you a lawyer by day and a
              poet by night? Are you a web developer or computer programmer
              interested in joining the Open Source movement? Do you spend your
              evenings working on a creative project like a podcast, fanzine, or
              illustration series? Get in touch with us at{" "}
              <a href="mailto:contact@crin.org">contact@crin.org</a>
            </p>
          </CardContent>
          <CardActions className="actions-sixty-days">
            <Button
              variant="outlined"
              href="mailto:contact@crin.org"
              className="bigger-button"
            >
              contact us
            </Button>
            <Button
              className="black-button bigger-button"
              variant="contained"
              color="primary"
              onClick={this.goToFinish.bind(this)}
            >
              next
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default withRouter(SixtyDays);
