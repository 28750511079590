export const getHeroFields = () => {
  return [
    {
      id: "name",
      className: "story-input name-input",
      label: "Name",
      type: "input"
    },
    {
      id: "superpowers",
      className: "story-input superpowers-input",
      label: "Superpowers",
      type: "input"
    },
    {
      id: "protects",
      className: "story-input protects-input",
      label: "Protects",
      type: "input"
    },
    {
      id: "fights",
      className: "story-input fights-input",
      label: "Fights against",
      type: "input"
    },
    {
      id: "accident",
      className: "story-input accident-input",
      label: "Secret accident or trauma",
      type: "input"
    },
    {
      id: "nemesis",
      className: "story-input nemesis-input",
      label: "Nemesis",
      type: "input"
    },
    {
      id: "problem",
      className: "story-input problem-input",
      label: "A problem your superhero overcame",
      type: "input"
    },
    {
      id: "change",
      className: "story-input change-input",
      label:
        "If your superhero won their hardest fight, the biggest world change would be...",
      type: "area"
    }
  ];
};
