const squares = [
  {
    id: "1",
    text: "Children belong in school, not protests",
    hasBeenSeen: false,
    isSelected: false,
    elaborationText: `This is what some schools and politicians have said to students who skip classes to protest against the lack of government action on climate change. But in the words of youth climate activist Greta Thunberg, this kind of thinking “belongs in a museum”. Children do have a right to education, but it’s one of many rights. They also have freedom of expression and the right to protest. Governments have a responsibility to act on climate change, but in the face of their inaction we should all be able to mobilise.`,
    elaborationImage: "image1.png"
  },
  {
    id: "2",
    text: "Children don’t need to vote",
    hasBeenSeen: false,
    isSelected: false,
    elaborationText: `No one needs to vote; our survival is not dependent on it. But our civil and political rights are. These rights for under-18s, however, are systematically denied because of assumptions like: children don’t understand politics, parents would influence their vote, or they probably wouldn’t vote anyway. But these are all things that also affect adult voters, yet no one is taking away adults’ right to vote. The truth is, everyone, regardless of age, should be allowed to vote if their interests and knowledge motivate them to vote and they’re able to register. `,
    elaborationImage: "image2.png"
  },
  {
    id: "3",
    text: "Parents can manage their children’s bodies",
    hasBeenSeen: false,
    isSelected: false,
    elaborationText: `Everyone, including children, has the right to autonomy and self-determination over their own body. This means that, while parents can make decisions that protect their children, they can’t consent to practices that harm them. These include denying a young person life-saving medical treatment or surgeries to alter their genitalia for no medical reason. Children are especially vulnerable to these practices because they’re done at an age when they’re unable to defend themselves. But to be clear, the only person with the right to make a decision about one’s body is oneself—no one else. `,
    elaborationImage: "image3.png"
  },
  {
    id: "4",
    text: "It’s okay to physically discipline children",
    hasBeenSeen: false,
    isSelected: false,
    elaborationText: `People don’t smack their mother or their colleagues for not following their instructions, but it’s not uncommon for parents to smack their children for the same reason. In over 140 countries it’s legal to hit your child in the name of discipline. But this doesn’t mean it’s OK. In fact, it would be criminal assault if done against an adult. Corporal punishment of children is simply an abuse of power that sees a fully-grown adult hitting a child half their size who is unable to defend themself.`,
    elaborationImage: "image4.png"
  },
  {
    id: "5",
    text: "Internet restrictions protect children",
    hasBeenSeen: false,
    isSelected: false,
    elaborationText: `Children have a right to protection, but they also have a right to access honest and objective information appropriate to their age. Online restrictions block harmful or inappropriate content, but they also block websites about politics, sexual health, drug use, and even children’s rights organisations—all of which can actually contribute to children’s protection. To really protect children, we need to support them to think critically and to make safe, informed choices. Undoubtedly, information is power.`,
    elaborationImage: "image5.png"
  },
  {
    id: "6",
    text: "Children who break the law should be imprisoned",
    hasBeenSeen: false,
    isSelected: false,
    elaborationText: `When a crime is committed, it’s essential to take responsibility for one’s actions. But imprisonment isn’t about taking responsibility; it’s only objective is to punish. Children in conflict with the law should be rehabilitated back into society, but incarceration only isolates them from their society. It also causes family separation, risk of violence inside the prison, and disruption to education and job opportunities. Evidence shows this all in fact pushes children to return to crime. And let’s not forget who children in conflict with the law are: they are not hardened criminals; they are children first and foremost and should be treated and protected as children. A caring society recognises this.`,
    elaborationImage: "image6.png"
  },
  {
    id: "7",
    text: "Children will follow the religion they’re taught",
    hasBeenSeen: false,
    isSelected: false,
    elaborationText: `Religious views are imposed on children more intensely and in more settings (home, school, government) than any other population group. But things like indoctrination, forced conversion or any assumption that children will grow up to follow a given religion, is incompatible with children’s own independent freedom to decide their own beliefs and to choose to practise a religion or no religion. This right is protected as part of a child’s civil rights. `,
    elaborationImage: "image7.png"
  },
  {
    id: "8",
    text: "Child terrorists are a lost cause",
    hasBeenSeen: false,
    isSelected: false,
    elaborationText: `Children who join terrorists groups have usually been groomed and indoctrinated by adults preying on their vulnerability. Children join these groups looking for a community and a place where they belong only to be manipulated by the adults they put their trust in. They are subjected to violence - physical, sexual and psychological - and forced to commit crimes. Casting these children aside for life, rather than working out how they can return home, overcome the trauma they have experienced and live lives that contribute to their communities gives an easy win to terrorism.`,
    elaborationImage: "image8.png"
  },
  {
    id: "9",
    text: "Two euros a month will help to save a child",
    hasBeenSeen: false,
    isSelected: false,
    elaborationText: `The reasons why a child may be suffering poverty or famine cannot be fixed with donations. These are caused by enormous problems like political corruption, unjust economic policies or war. It’s not that charity is wrong; but simply giving money can make us feel like we’re helping to save the world when, in fact, we are not. Charity can make us lazy and prevents us from doing more effective things, like donating our time. We can join protest marches, organise a local debate, write articles that change attitudes towards children and, most importantly, have empathy and think constructively.`,
    elaborationImage: "image9.png"
  }
];

export const createSquares = () => {
  return squares;
};
