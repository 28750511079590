import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

class ShareModal extends React.Component {
  render() {
    let shareUrl = this.props.url;
    const { fullScreen } = this.props;
    const subject = "Break to create";
    const hashtag = "#breaktocreate";
    const hashtags = ["#breaktocreate"];
    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.props.open}
        onClose={this.props.handleClosePopup}
        aria-labelledby="responsive-dialog-title"
        className="my-hero-share--modal"
      >
        <DialogContent className="my-hero-share-content">
          <div className="social-media-buttons">
            <FacebookShareButton hashtag={hashtag} url={shareUrl}>
              <FacebookIcon />
            </FacebookShareButton>
            <TwitterShareButton
              hashtags={hashtags}
              title={subject}
              url={shareUrl}
            >
              <TwitterIcon />
            </TwitterShareButton>
            <WhatsappShareButton title={subject} url={shareUrl}>
              <WhatsappIcon />
            </WhatsappShareButton>
            <EmailShareButton subject={subject} url={shareUrl}>
              <EmailIcon />
            </EmailShareButton>
          </div>
          <Button
            variant="contained"
            color="primary"
            className="black-button bigger-button"
            onClick={this.props.handleClosePopup}
          >
            back
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
}

ShareModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ShareModal);
