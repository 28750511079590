import TweenMax from "gsap/TweenMax";
import TimelineMax from "gsap/TimelineMax";
import Fragment from "./fragment";
import { Power2 } from "gsap";
import Delaunator from "delaunator";

const TWO_PI = Math.PI * 2;

// Original values in comments
const MIN_RANGE_DURATION = 0;
const MAX_RANGE_DURATION = 3;
const TIMELINE_DURATION = 0.1;
const RINGS_POSITIONS_VERTICES = [20, 40, 80, 160]; // This defines how big the shatter rings are

const randomRange = (min, max) => {
  return min + (max - min) * Math.random();
};

const clamp = (x, min, max) => {
  return x < min ? min : x > max ? max : x;
};

const calculateImageDimensions = image => {
  var box = image.getBoundingClientRect();
  let { top, left, right, bottom } = box;

  let imageWidth = right - left;
  let imageHeight = bottom - top;
  return {
    imageWidth,
    imageHeight
  };
};

const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const shatter = ({ image, containerElement, event }) => {
  let imageDimensions = calculateImageDimensions(image);
  let shatterPoint = [
    imageDimensions.imageWidth / 2 + randomNumber(-10, 10),
    imageDimensions.imageHeight / 2 + randomNumber(-10, 10)
  ];
  let { vertices, indices } = triangulate(
    shatterPoint,
    calculateImageDimensions(image)
  );

  return new Promise(resolve => {
    let fragments = [];
    TweenMax.set(containerElement, { perspective: 500 });
    var p0, p1, p2, fragment;
    var tl0 = new TimelineMax({
      onComplete: () => {
        fragments.forEach(function(f) {
          containerElement.removeChild(f.canvas);
        });
        resolve();
      }
    });

    for (var i = 0; i < indices.length; i += 3) {
      p0 = vertices[indices[i + 0]];
      p1 = vertices[indices[i + 1]];
      p2 = vertices[indices[i + 2]];

      fragment = new Fragment(p0, p1, p2, image);

      let dx = fragment.centroid[0] - shatterPoint[0],
        dy = fragment.centroid[1] - shatterPoint[1],
        d = Math.sqrt(dx * dx + dy * dy),
        delay = d * 0.003 * randomRange(MIN_RANGE_DURATION, MAX_RANGE_DURATION);
      fragment.canvas.style.zIndex = Math.floor(d).toString();

      let tl1 = new TimelineMax();
      tl1.to(fragment.canvas, 1.1, {
        z: -500,
        y: 1000,
        rotationX: 180,
        rotationY: 180,
        rotationZ: 180,
        ease: Power2.easeIn
      });
      tl1.to(fragment.canvas, TIMELINE_DURATION, { alpha: 0 }, 0.6);

      tl0.insert(tl1, delay);

      fragments.push(fragment);
      containerElement.appendChild(fragment.canvas);
    }

    containerElement.removeChild(image);
  });
};

export const triangulate = (shatterPoint, imageDimensions) => {
  let width = imageDimensions.imageWidth;
  let height = imageDimensions.imageHeight;

  let vertices = [];
  let r = RINGS_POSITIONS_VERTICES;
  let rings = [
    { r: r[0], c: 12 },
    { r: r[1], c: 12 },
    { r: r[2], c: 12 },
    { r: r[3], c: 12 } // very large in case of corner clicks
  ];
  let x, y;
  let centerX = shatterPoint[0];
  let centerY = shatterPoint[1];

  vertices.push([centerX, centerY]);

  vertices.push([0, 0]);
  vertices.push([0, height]);
  vertices.push([width, height]);
  vertices.push([width, 0]);

  rings.forEach(ring => {
    var radius = ring.r,
      count = ring.c,
      variance = radius * 0.25;
    for (var i = 0; i < count; i++) {
      x =
        Math.cos((i / count) * TWO_PI) * radius +
        centerX +
        randomRange(-variance, variance);
      y =
        Math.sin((i / count) * TWO_PI) * radius +
        centerY +
        randomRange(-variance, variance);
      vertices.push([x, y]);
    }
  });

  vertices.forEach(v => {
    v[0] = clamp(v[0], 0, width);
    v[1] = clamp(v[1], 0, height);
  });

  let indices = Delaunator.from(vertices).triangles;

  return { indices, vertices };
};
