import React from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import RenderHero from "./RenderHero";
import { generateHeroUrl, getAccessoryUrls } from "../utils/image-urls";
import Button from "@material-ui/core/Button";
import ShareModal from "./ShareModal";
import { getHeroFields } from "../data/hero-fields";

class MyHero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingSharePopup: false
    };
  }
  componentWillMount() {
    let queryString = this.props.location.search;
    if (!queryString) {
      return;
    }
    const params = new URLSearchParams(queryString);
    const hero = JSON.parse(atob(params.get("hero")));
    const details = JSON.parse(atob(params.get("details")));
    this.setState({ hero, details });
  }
  share() {
    this.setState({ isShowingSharePopup: true });
  }
  next() {
    this.props.history.push("/finish");
  }
  generateHeroDetailsMarkup() {
    let details = this.state.details;
    const heroFields = getHeroFields();
    return heroFields.map(field => {
      let value = details[field.id];

      return (
        <p key={field.id} className="hero-field">
          <strong className="field-label">{field.label}:&nbsp;</strong>
          <span className="field-value">{value}</span>
        </p>
      );
    });
  }
  generateHeroName() {
    let details = this.state.details;
    return <h1>{details.name}</h1>;
  }

  handleClosePopup() {
    this.setState({
      isShowingSharePopup: false
    });
  }
  render() {
    let headImage = generateHeroUrl(this.state.hero.head);
    let torsoImage = generateHeroUrl(this.state.hero.torso);
    let legsImage = generateHeroUrl(this.state.hero.legs);
    let accessoryImages = getAccessoryUrls(this.state.hero.accessories);
    const heroName = this.generateHeroName();
    const heroDetailsMarkup = this.generateHeroDetailsMarkup();
    let shareUrl = window.location.href;
    return (
      <div className="route-my-hero">
        {heroName}
        <Grid container spacing={16}>
          <Grid item xs={12} md={6}>
            <div className="layout-hero">
              <div className="left-scrollers" />
              <div className="hero-frame">
                <div className="image">
                  <RenderHero
                    headImage={headImage}
                    torsoImage={torsoImage}
                    legsImage={legsImage}
                    accessoryImages={accessoryImages}
                    canvasId={this.state.canvasId}
                  />
                </div>
              </div>
              <div className="right-scrollers" />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="right-column">
            <div className="hero-fields">{heroDetailsMarkup}</div>
            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                className="black-button bigger-button"
                onClick={this.share.bind(this)}
              >
                share
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="black-button bigger-button"
                onClick={this.next.bind(this)}
              >
                next
              </Button>
            </div>
            <ShareModal
              open={this.state.isShowingSharePopup}
              handleClosePopup={this.handleClosePopup.bind(this)}
              url={shareUrl}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(MyHero);
