import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

class Finish extends React.Component {
  goToChoices() {
    this.props.history.push("/choose");
  }
  render() {
    return (
      <div className="route-finish">
        <div className="finish-body">
          <h1 className="thank-you">Thank You!</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={this.goToChoices.bind(this)}
            className="black-button bigger-button"
          >
            home
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(Finish);
