import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

class SixtyMinutes extends React.Component {
  componentWillMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  goToBuild() {
    this.props.history.push("/build-hero");
  }
  render() {
    return (
      <div className="route-sixty-minutes">
        <Card className="card-sixty-minutes">
          <CardContent>
            <h1>
              60 minutes - Create your own superhero and write their story
            </h1>
            <p>
              We think everyone can be a superhero in our own way, with powers
              that come from our everyday lives, actions and ideas. This
              exercise will help to reveal how ordinary qualities or objects can
              be superpowers and how you can use them. But, on the next page,
              don’t just select powers; think about their potential and what
              they can achieve. In other words, what makes them super?
            </p>
          </CardContent>
          <CardActions className="actions-sixty-minutes">
            <Button
              className="black-button bigger-button"
              variant="contained"
              color="primary"
              onClick={this.goToBuild.bind(this)}
            >
              next
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default withRouter(SixtyMinutes);
