import React from "react";
import Grid from "@material-ui/core/Grid";
import { createSquares } from "../utils/squares";
import Square from "./Square";
import BreakModal from "./BreakModal";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import find from "lodash/find";
import ShareModal from "./ShareModal";

class Break extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      squares: createSquares(),
      preloadedImages: [],
      isShowingPopup: false,
      selectedSquare: null,
      isShowingSharePopup: false
    };
  }

  componentWillMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.preloadImages();
    let queryString = this.props.location.search;
    if (!queryString) {
      return;
    }
    const params = new URLSearchParams(queryString);
    let id = params.get("selected");
    if (id) {
      let square = find(this.state.squares, { id });
      const index = this.state.squares.indexOf(square);
      const newSquare = {
        ...square,
        isSelected: true
      };
      const newArray = this.replaceAt(this.state.squares, index, newSquare);
      this.props.history.push({ search: `?selected=${square.id}` });
      this.setState({
        squares: newArray,
        selectedSquare: newSquare
      });
      this.showPopup(square);
    }
  }

  preloadImages() {
    let preloadedImages = [];
    this.createImage("images/black-background.png");
    let squares = this.state.squares;
    squares.forEach(square => {
      let url = `images/${square.elaborationImage}`;
      preloadedImages.push(this.createImage(url));
    });
    this.setState({ preloadedImages });
  }

  createImage(url) {
    var img = new Image();
    img.src = url;
  }

  replaceAt(array, index, value) {
    const ret = array.slice(0);
    ret[index] = value;
    return ret;
  }

  showPopup() {
    this.setState({
      isShowingPopup: true
    });
  }

  handleClosePopup() {
    const newSquare = {
      ...this.state.selectedSquare,
      isSelected: false
    };
    const index = this.state.squares.indexOf(this.state.selectedSquare);
    const newArray = this.replaceAt(this.state.squares, index, newSquare);
    this.props.history.push({ search: null });
    this.setState({
      isShowingPopup: false,
      squares: newArray
    });
  }

  clickedOnSquare(square) {
    const index = this.state.squares.indexOf(square);
    const newSquare = {
      ...square,
      isSelected: true,
      hasBeenSeen: true
    };
    const newArray = this.replaceAt(this.state.squares, index, newSquare);
    this.props.history.push({ search: `?selected=${square.id}` });
    this.setState({
      squares: newArray,
      selectedSquare: newSquare
    });
  }

  goHome() {
    this.props.history.push("/");
  }

  handleCloseSharePopup() {
    this.setState({
      isShowingSharePopup: false
    });
  }

  share() {
    this.setState({
      isShowingSharePopup: true
    });
  }

  render() {
    const squares = this.state.squares;
    const selectedSquare = this.state.selectedSquare;
    const shareUrl = window.location.href;
    let selectedElaborationText = "";
    let selectedElaborationImage = "";
    if (selectedSquare) {
      selectedElaborationText = selectedSquare.elaborationText;
      selectedElaborationImage = selectedSquare.elaborationImage;
    }
    const markup = squares.map((square, i) => {
      return (
        <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
          <Square
            square={square}
            clickedOnSquare={this.clickedOnSquare.bind(this)}
            showPopup={this.showPopup.bind(this)}
            share={this.share.bind(this)}
          />
        </Grid>
      );
    });
    return (
      <div className="route-break">
        <div className="grid-container">
          <Grid container spacing={16}>
            {markup}
          </Grid>
        </div>
        <Button
          variant="contained"
          color="primary"
          className="black-button bigger-button"
          onClick={this.goHome.bind(this)}
        >
          home
        </Button>
        <BreakModal
          selectedElaborationImage={selectedElaborationImage}
          selectedElaborationText={selectedElaborationText}
          open={this.state.isShowingPopup}
          handleClosePopup={this.handleClosePopup.bind(this)}
          share={this.share.bind(this)}
        />
        <ShareModal
          open={this.state.isShowingSharePopup}
          handleClosePopup={this.handleCloseSharePopup.bind(this)}
          url={shareUrl}
        />
      </div>
    );
  }
}

export default withRouter(Break);
