import React from "react";
import { shatter } from "../utils/shatter";

class Square extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      hasBeenSmashed: false
    };
  }
  onClick(e) {
    if (!this.state.hasBeenSmashed) {
      this.props.clickedOnSquare(this.props.square);
      this.setState({ hasBeenSmashed: true });
      this.handleSmash(e).then(() => {
        this.props.showPopup(this.props.square);
      });
    } else {
      this.props.clickedOnSquare(this.props.square);
      this.props.showPopup(this.props.square);
    }
  }
  getContainer() {
    return document.getElementById(this.props.square.id);
  }
  componentDidMount() {
    let image = this.createImage("/images/black-background.png");
    this.setState({ image });
    let container = this.getContainer();
    container.appendChild(image);
  }
  createImage(url) {
    let img = new Image();
    img.src = url;
    return img;
  }
  async handleSmash(event) {
    let image = this.state.image;
    let containerElement = this.getContainer();
    await shatter({ image, containerElement, event });
  }
  render() {
    let { hasBeenSeen } = this.props.square;
    let squareClass = "square";
    if (hasBeenSeen) {
      squareClass = `${squareClass} has-been-seen`;
    }
    return (
      <div className={squareClass} onClick={this.onClick.bind(this)}>
        <div className="canvas-container" id={this.props.square.id} />
        <div className="square-text">{this.props.square.text}</div>
      </div>
    );
  }
}

export default Square;
