import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import PropTypes from "prop-types";
import { generateUrl } from "../utils/image-urls";

class BreakModal extends React.Component {
  getImage(image) {
    if (image) {
      let src = generateUrl(image);
      return <img className="break-modal-image" src={src} alt="alt tag" />;
    }
  }
  render() {
    let selectedElaborationImage = "";
    if (this.props.selectedElaborationImage) {
      selectedElaborationImage = this.getImage(
        this.props.selectedElaborationImage
      );
    }
    const { fullScreen } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.props.open}
        onClose={this.props.handleClosePopup}
        aria-labelledby="responsive-dialog-title"
        className="break-modal"
      >
        <DialogContent className="break-modal-content">
          <div className="break-modal-body">
            {selectedElaborationImage}
            <div className="break-modal-text">
              <p>{this.props.selectedElaborationText}</p>
            </div>
          </div>
          <div className="break-modal-actions">
            <Button
              variant="outlined"
              onClick={this.props.handleClosePopup}
              className="bigger-button"
            >
              next
            </Button>
            <Button
              variant="outlined"
              onClick={this.props.share}
              className="bigger-button"
            >
              share
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

BreakModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(BreakModal);
