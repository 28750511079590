export const generateUrl = filename => {
  return `${process.env.PUBLIC_URL}/images/${filename}`;
};

export const generateHeroUrl = filename => {
  return `${process.env.PUBLIC_URL}/images/hero/${filename}`;
};

export const getAccessoryUrls = accessories => {
  return accessories.map(accessory => {
    return generateHeroUrl(accessory);
  });
};
