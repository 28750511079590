import React from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

class SixtyDays extends React.Component {
  componentWillMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  goHome() {
    this.props.history.push("/choose");
  }
  render() {
    return (
      <div className="route-info">
        <Card className="card-info">
          <CardContent>
            <h1 class="underlined">About CRIN</h1>
            <h2>This is who we are</h2>
            <p>
              CRIN is a creative think tank that produces new and dynamic
              perspectives on human rights issues, with a focus on children and
              young people. We challenge the status quo because the norms that
              dictate under-18s’ place in society need radical change. Through
              research, artwork and our vision for the future, we encourage
              people to think critically about the world and start their own
              conversations.
            </p>
            <h2>This is what we’re fighting for</h2>
            <p>
              Our goal is a world where the human rights of children and young
              people are recognised, respected and enforced, and where every
              rights violation has a remedy. Our focus for the coming years is
              to change the narrative about children and their rights; build a
              collective; and seek justice when rights are violated.
            </p>
            <h2>These are our principles</h2>
            <h3>Part I - We have a mission</h3>
            <ul>
              <li>Rights, not charity</li>
              <li>Children’s rights, human rights</li>
              <li>Justice, not compromise</li>
              <li>Accountability, not apology</li>
              <li>Diversity, not homogeny</li>
              <li>We are Earth’s custodians, not its owners</li>
            </ul>
            <h3>Part II - We have an attitude</h3>
            <ul>
              <li>Principles, not pragmatism</li>
              <li>Critical thinking, not compliance</li>
              <li>Feminism, not patriarchy</li>
              <li>If you can’t imagine it, you can’t achieve it</li>
              <li>Promote children’s rights, not ourselves</li>
              <li>Invest in failure, not quick wins</li>
            </ul>
            <h3>Part III - We have the means</h3>
            <ul>
              <li>Advocate in language, not words</li>
              <li>Don’t grow; network</li>
              <li>Stronger together, not apart</li>
              <li>Be open, not proprietary</li>
              <li>Think ethically, be ethical</li>
              <li>Safeguarding children, not the organisation</li>
            </ul>
            <p>
              Read The CRIN Code:{" "}
              <a href="https://crin.org/the-crin-code">
                https://crin.org/the-crin-code
              </a>
            </p>
            <h3>Invest</h3>
            <p>
              We want people to support our work as an investment for a better
              future. The sort of work we do isn’t counted in number of children
              ‘saved’, but aims at more radical, systemic changes to the way
              that societies, legal systems and even human rights organisations
              treat under-18s.
            </p>
            Invest:{" "}
            <a href="https://home.crin.org/invest">
              https://home.crin.org/invest
            </a>
            <h1 class="underlined">About BreaktoCreate</h1>
            <p>
              This project is part of the ARTIVISM exhibition at the 2019 Venice
              Biennale that explores the creative responses of artists and
              artistically minded activists to the most precarious of
              situations: large-scale identity-based violence and mass atrocity.
              CRIN is working in collaboration with the Auschwitz Institute for
              Peace and Reconciliation. They have seen the fundamental role that
              the arts have played in the promotion of human rights and the
              positive transformation of societies both during and in the
              aftermath of mass atrocities.
            </p>
            <p>
              More than merely showing the way others have used the arts to
              foment positive change, Artivism also provide concrete steps for
              visitors themselves to take action, highlighting their own power
              to play a role in mass atrocity prevention at home and around the
              world.
            </p>
            <p>Breaktocreate is CRIN’s contribution to the Artivism project.</p>
            <p>
              To find out more about Artivism, visit the official website:{" "}
              <a href="https://www.artivism2019.com/">
                https://www.artivism2019.com/
              </a>
            </p>
            <br />
            <p>
              Special thanks Luke Clewlow and Peter Worth for building this
              website.
            </p>
            <p>
              This website is hosted by Greenhost, a green, sustainable and
              ethical company whose servers are hosted at the climate neutral
              IronMountain data centre.
            </p>
            <br />
          </CardContent>
          <CardActions className="actions-info">
            <Button
              className="black-button bigger-button"
              variant="contained"
              color="primary"
              onClick={this.goHome.bind(this)}
            >
              home
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default withRouter(SixtyDays);
