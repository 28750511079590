import React from "react";
import Main from "./Main";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000"
    },
    secondary: {
      main: "#000000"
    }
  }
});

const App = () => (
  <div>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <Main />
    </MuiThemeProvider>
  </div>
);

export default App;
