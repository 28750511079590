import React from "react";
import CreateHero from "./CreateHero";
import MyStory from "./MyStory";
import MyHero from "./MyHero";
import { withRouter } from "react-router-dom";
import Firebase from "firebase/app";
import "firebase/database";

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBuildingHero: true,
      isTellingStory: false,
      hero: {},
      heroDetails: {
        name: ""
      }
    };
  }

  updateHero(hero) {
    this.setState({ isBuildingHero: false, isTellingStory: true, hero });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  updateStory(story) {
    this.setState({ story });
  }

  updateName(name) {
    this.setState({ name });
  }

  updateHeroDetails({ id, value }) {
    let newDetails = { ...this.state.heroDetails };
    newDetails[id] = value;
    this.setState({
      heroDetails: newDetails
    });
  }

  returnToCreateHero() {
    this.setState({ isBuildingHero: true, isTellingStory: false });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  buildQueryString() {
    let hero = btoa(JSON.stringify(this.state.hero.images));
    let details = btoa(JSON.stringify(this.state.heroDetails));
    return `?hero=${hero}&details=${details}`;
  }

  goToMyHero() {
    let queryParams = this.buildQueryString();
    this.saveToDatabase(queryParams);
    this.props.history.push({
      pathname: "/my-hero",
      search: queryParams
    });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  saveToDatabase(url) {
    const db = Firebase.firestore();
    const png = this.state.hero.png;
    let tablename = "heroes-development";
    if (process.env.NODE_ENV === "production") {
      tablename = "heroes-production";
    }
    db.collection(tablename).add({
      png,
      url,
      details: this.state.heroDetails,
      createdAt: Firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  render() {
    if (this.state.isBuildingHero) {
      return (
        <CreateHero
          selectedIndexes={this.state.hero.indexes}
          selectedAccessories={this.state.hero.accessories}
          updateHero={this.updateHero.bind(this)}
        />
      );
    }
    if (this.state.isTellingStory) {
      return (
        <MyStory
          hero={this.state.hero.images}
          returnToCreateHero={this.returnToCreateHero.bind(this)}
          updateHeroDetails={this.updateHeroDetails.bind(this)}
          heroDetails={this.state.heroDetails}
          goToMyHero={this.goToMyHero.bind(this)}
        />
      );
    }
    return <MyHero />;
  }
}
export default withRouter(Hero);
