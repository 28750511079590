import React from "react";
import GetImages from "../data/hero-images";
import reject from "lodash/reject";
import RenderHero from "./RenderHero";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { generateHeroUrl } from "../utils/image-urls";
import { withRouter } from "react-router-dom";

class CreateHero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headImage: null,
      possibleImages: this.setupPossibleImages(),
      selectedIndexes: {
        head: 0,
        torso: 0,
        legs: 0
      },
      selectedAccessories: [],
      canvasId: "my-hero"
    };
  }

  componentWillMount() {
    if (this.props.selectedIndexes) {
      console.log("moutning");
      console.log(this.props);
      this.setState({
        selectedIndexes: this.props.selectedIndexes,
        selectedAccessories: this.props.selectedAccessories
      });
    }
  }

  setupPossibleImages() {
    return GetImages();
  }

  generateUrl(filename) {
    return generateHeroUrl(filename);
  }

  setNewIndex(type, newIndex) {
    let newIndexes = {
      ...this.state.selectedIndexes
    };
    newIndexes[type] = newIndex;
    this.setState({
      selectedIndexes: newIndexes
    });
  }

  previous(type) {
    let newIndex;
    let currentIndex = this.state.selectedIndexes[type];
    if (currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else {
      newIndex = this.state.possibleImages[type].length - 1;
    }
    this.setNewIndex(type, newIndex);
  }

  next(type) {
    let newIndex;
    let currentIndex = this.state.selectedIndexes[type];
    if (currentIndex < this.state.possibleImages[type].length - 1) {
      newIndex = currentIndex + 1;
    } else {
      newIndex = 0;
    }
    this.setNewIndex(type, newIndex);
  }

  getSelected(type) {
    return this.state.possibleImages[type][this.state.selectedIndexes[type]];
  }

  deselectAccessory(accessory) {
    let accessories = reject(this.state.selectedAccessories, {
      menuSrc: accessory.menuSrc
    });
    this.setState({
      selectedAccessories: [...accessories]
    });
  }

  // accessoryAlreadySelected(accessory) {
  //   console.log("accessoryAlreadySelected");
  //   console.log(accessory);
  //   console.log(this.state.selectedAccessories);
  //   return this.state.selectedAccessories.includes(accessory);
  // }

  selectAccessory(accessory) {
    console.log("select");
    console.log(accessory);
    if (this.isAccessorySelected(accessory)) {
      console.log("already selected");
      return this.deselectAccessory(accessory);
    }
    this.setState({
      selectedAccessories: [...this.state.selectedAccessories, accessory]
    });
  }

  isAccessorySelected(accessory) {
    let accessorySrcs = this.state.selectedAccessories.map(selected => {
      return selected.menuSrc;
    });
    // console.log("isSelected", accessorySrcs, accessory.menuSrc);
    return accessorySrcs.includes(accessory.menuSrc);
  }

  createAccessoriesMenuMarkup() {
    return this.state.possibleImages["accessories"].map(accessory => {
      let classNames = "";
      if (this.isAccessorySelected(accessory)) {
        classNames = `${classNames} is-selected`;
      }
      return (
        <div
          className="accessory"
          onClick={this.selectAccessory.bind(this, accessory)}
          key={accessory.menuSrc}
        >
          <img
            className={classNames}
            key={accessory.menuSrc}
            src={this.generateUrl(accessory.menuSrc)}
            alt="accessory"
          />
        </div>
      );
    });
  }

  getAccessoryUrls() {
    let accessories = this.state.selectedAccessories;
    return accessories.map(accessory => {
      let index = 0;
      if (accessory.position === "head") {
        index = this.state.selectedIndexes.head;
      } else if (accessory.position === "torso") {
        index = this.state.selectedIndexes.torso;
      } else if (accessory.position === "legs") {
        index = this.state.selectedIndexes.legs;
      }

      return this.generateUrl(accessory.srcs[index]);
    });
  }

  getAccessories() {
    let accessories = this.state.selectedAccessories;
    return accessories.map(accessory => {
      let index = 0;
      if (accessory.position === "head") {
        index = this.state.selectedIndexes.head;
      } else if (accessory.position === "torso") {
        index = this.state.selectedIndexes.torso;
      } else if (accessory.position === "legs") {
        index = this.state.selectedIndexes.legs;
      }

      return accessory.srcs[index];
    });
  }

  done() {
    this.exportCanvasAsPNG(this.state.canvasId, "my-hero-to-download");
  }

  exportCanvasAsPNG(id, fileName) {
    let canvasElement = document.getElementById(id);
    let MIME_TYPE = "image/png";
    let imgURL = canvasElement.toDataURL(MIME_TYPE);
    let dlLink = document.createElement("a");

    dlLink.download = fileName;
    dlLink.href = imgURL;
    dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(
      ":"
    );

    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  }

  convertToPng(canvasId) {
    let canvasElement = document.getElementById(canvasId);
    let MIME_TYPE = "image/png";
    let imgURL = canvasElement.toDataURL(MIME_TYPE);
    return imgURL;
  }

  nextPage() {
    this.props.updateHero({
      images: {
        head: this.getSelected("head").src,
        torso: this.getSelected("torso").src,
        legs: this.getSelected("legs").src,
        accessories: this.getAccessories()
      },
      indexes: this.state.selectedIndexes,
      accessories: this.state.selectedAccessories,
      png: this.convertToPng(this.state.canvasId)
    });
  }

  goHome() {
    this.props.history.push("/");
  }

  render() {
    let headImage = this.generateUrl(this.getSelected("head").src);
    let torsoImage = this.generateUrl(this.getSelected("torso").src);
    let legsImage = this.generateUrl(this.getSelected("legs").src);
    let accessoryImages = this.getAccessoryUrls();
    let accessoriesMenu = this.createAccessoriesMenuMarkup();
    return (
      <div className="route-hero">
        <h1>Create a superhero</h1>
        <div className="layout">
          <Grid container spacing={16} className="create-hero-grid-container">
            <Grid item xs={12} md={6}>
              <div className="layout-hero">
                <div className="left-scrollers">
                  <div
                    className="third"
                    onClick={this.previous.bind(this, "head")}
                  >
                    <IconButton aria-label="Delete">
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                  </div>
                  <div
                    className="third"
                    onClick={this.previous.bind(this, "torso")}
                  >
                    <IconButton aria-label="Delete">
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                  </div>
                  <div
                    className="third"
                    onClick={this.previous.bind(this, "legs")}
                  >
                    <IconButton aria-label="Delete">
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="hero-frame">
                  <div className="image">
                    <RenderHero
                      headImage={headImage}
                      torsoImage={torsoImage}
                      legsImage={legsImage}
                      accessoryImages={accessoryImages}
                      canvasId={this.state.canvasId}
                    />
                  </div>
                </div>
                <div className="right-scrollers">
                  <div className="third" onClick={this.next.bind(this, "head")}>
                    <IconButton aria-label="Delete">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </div>
                  <div
                    className="third"
                    onClick={this.next.bind(this, "torso")}
                  >
                    <IconButton aria-label="Delete">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </div>
                  <div className="third" onClick={this.next.bind(this, "legs")}>
                    <IconButton aria-label="Delete">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} className="right-column">
              <div className="layout-accessories">
                <h2>Choose Powers</h2>
                <div className="accessories">{accessoriesMenu}</div>
              </div>
              <div className="buttons">
                <Button
                  variant="contained"
                  color="primary"
                  className="black-button bigger-button"
                  onClick={this.goHome.bind(this)}
                >
                  home
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="black-button bigger-button"
                  onClick={this.nextPage.bind(this)}
                >
                  next
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default withRouter(CreateHero);
